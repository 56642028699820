import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  useRef,
  Fragment,
} from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { UserDataContext } from "../../../../components/app/UserData";
import { AuthContext } from "../../../../components/auth/FirebaseAuthContext";
import { SegmentsContext } from "../../../../context/Segments";
import { useTable, useFilters, usePagination, useSortBy } from "react-table";
import { fetchFunnels } from "../../../../lib/firebase/funnels";
import { Button } from "../../../../components/share/InsightUI";
import { fetchUsersByProfileId } from "../../../../lib/firebase/user";
import { DEFAULT_FUNNEL_FOLDER_NAME } from "../../../../constants/funnels";
import {
  captureExceptionToSentry,
  captureQueryCancelMsgToSentry,
  defaultSortOrder,
  getProfilePref,
  saveProfilePref,
} from "../../../../utils";
import OutlinedContainer from "../../../../components/share/OutlinedContainer";
import api from "../../../../api";
import LoaderWithText from "../../../../components/share/LoaderWithText";
import UserAvatar from "../../../../components/app/UserAvatar";
import "./style.scss";
import {
  BYTES_BILL_LIMIT_EXCEED_ERROR,
  DEFAULT_ERROR,
} from "../../../../constants/error";
import Alert from "../../../../components/share/Alert";

function Funnels(props) {
  const action = props.match.params.action;
  const profileId = props.match.params.profileId;
  const readFunnelStepsRetryMs = 750;

  const { authUser } = useContext(AuthContext);
  const {
    startDate,
    endDate,
    appliedFilters,
    isInitialFiltersLoaded,
    isApiServerLoading,
    profileUsers,
    setProfileUsers,
    funnelFolders,
    setFunnelFolders,
    selectedFunnelFolder,
    setSelectedFunnelFolder,
    funnelFoldersMap,
    setFunnelFoldersMap,
  } = useContext(UserDataContext);

  const { selectedSegments, isInitialSegmentsLoaded } =
    useContext(SegmentsContext);

  const [isLoadingFunnels, setIsLoadingFunnels] = useState(false);
  const [isFunnelsLoaded, setIsFunnelsLoaded] = useState(false);
  const [isDependenciesLoaded, setIsDependenciesLoaded] = useState(false);
  const [funnels, setFunnels] = useState([]);
  const [funnelError, setFunnelError] = useState();
  const [alert, setAlert] = useState({
    show: false,
    type: "",
    message: "",
    count: 0,
  });

  const funnelsStateRef = useRef();
  funnelsStateRef.current = funnels;
  const _isMounted = useRef(true);
  const funnelFolderButtonRef = useRef(null);
  const ongoingQueries = useRef([]);

  // Api calls mane are dynamic, depends on funnels configured in the profile
  const addOngoingQueries = (queryId) => {
    ongoingQueries.current.push(queryId);
  };

  const removeOngoingQueries = (queryId) => {
    ongoingQueries.current = ongoingQueries.current.filter(
      (query) => query !== queryId
    );
  };

  const cancelAllOngoingQueries = () => {
    ongoingQueries.current.forEach((query) => {
      api.cancel(query);
    });
  };

  const readFunnelSteps = (authUser, queryId, key, funnelId) => {
    let cancelTokenName = "readFunnelSteps" + funnelId;
    addOngoingQueries(cancelTokenName)
    api.cancel(cancelTokenName);
    api
      .readFunnelSteps(authUser, {
        profileId: profileId,
        queryId,
        key,
        funnelId,
      })
      .then((res) => {
        if (!_isMounted.current) {
          return;
        }
        removeOngoingQueries(cancelTokenName);

        if (res.data.status === 100) {
          // Let the browser rest before next attempt
          setTimeout(function () {
            readFunnelSteps(authUser, queryId, key, funnelId);
          }, readFunnelStepsRetryMs);
        }
        if (res.data.status === 200) {
          let tempFunnels = [...funnelsStateRef.current];
          for (let i = 0; i < tempFunnels.length; i++) {
            if (tempFunnels[i].ID === funnelId) {
              tempFunnels[i].StartedSessionCount =
                res.data.data.records[0].SessionCount;
              tempFunnels[i].CompletedSessionCount =
                res.data.data.records[
                  res.data.data.records.length - 1
                ].SessionCount;
            }
          }
          setFunnels(tempFunnels);
        }
      })
      .catch((e) => {
        removeOngoingQueries(cancelTokenName);
      });
  };

  // Main useEffect used for unmounting
  useEffect(() => {
    return () => {
      cancelAllOngoingQueries();
      _isMounted.current = false;
      
    };
  }, []);

  useEffect(() => {
    if (
      !isApiServerLoading &&
      isInitialFiltersLoaded &&
      isInitialSegmentsLoaded &&
      profileId &&
      action !== "view"
    ) {
      setIsDependenciesLoaded(true);
    }
  }, [
    isInitialFiltersLoaded,
    isApiServerLoading,
    isInitialSegmentsLoaded,
    profileId,
    action,
  ]);

  useEffect(() => {
    if (
      isDependenciesLoaded &&
      profileId &&
      startDate &&
      endDate &&
      selectedSegments &&
      appliedFilters &&
      action !== "view"
    ) {
      cancelAllOngoingQueries();
      setIsLoadingFunnels(true);
      const asyncCalls = [];
      let funnelPromiseIndex = 0;
      const updatedFunnelFoldersMap = new Map();
      updatedFunnelFoldersMap.set(DEFAULT_FUNNEL_FOLDER_NAME, 0);
      const updatedFunnelFolders = [
        {
          label: DEFAULT_FUNNEL_FOLDER_NAME,
          value: DEFAULT_FUNNEL_FOLDER_NAME,
        },
      ];

      // Users Promise
      if (!profileUsers.all.length) {
        asyncCalls.push(fetchUsersByProfileId(profileId));
        funnelPromiseIndex = 1;
      }

      // Funnels Promise
      asyncCalls.push(fetchFunnels({ profileId }));

      Promise.all(asyncCalls).then((res) => {
        if (!_isMounted.current) return;

        const users = {
          byId: {},
          all: [],
        };

        // Resolve Profile Users
        if (!profileUsers.all.length) {
          res[0].docs.forEach((doc) => {
            users.byId[doc.id] = doc.data();
            users.all.push(doc.id);
          });
          setProfileUsers(users);
        }

        // Resolve Funnels
        let funnelObjs = [];
        res[funnelPromiseIndex].forEach((funnelDoc) => {
          const folder = funnelDoc.data().folder;
          const folderCount = updatedFunnelFoldersMap.get(folder) || 0;
          const funnelId = funnelDoc.id;
          const usersData = profileUsers.all.length
            ? profileUsers.byId
            : users.byId;
          const owner = usersData[funnelDoc.data().ownerId];

          funnelObjs.push({
            ID: funnelId,
            Name: funnelDoc.data().name,
            StepCount: funnelDoc.data().steps.length,
            StartedSessionCount: -1,
            ConversionRate: -1,
            CompletedSessionCount: -1,
            Owner: owner
              ? owner
              : {
                  name: "Removed User",
                },
            folder: folder,
          });

          //process funnel folders
          if (!folder || folder === DEFAULT_FUNNEL_FOLDER_NAME) {
            updatedFunnelFoldersMap.set(
              DEFAULT_FUNNEL_FOLDER_NAME,
              (updatedFunnelFoldersMap.get(DEFAULT_FUNNEL_FOLDER_NAME) || 0) + 1
            );
          } else {
            updatedFunnelFoldersMap.set(folder, folderCount + 1);
          }

          if (folder) {
            if (!updatedFunnelFolders.find((res) => res.value === folder)) {
              updatedFunnelFolders.push({
                label: folder,
                value: folder,
              });
            }
          }
        });
        setFunnelFoldersMap(updatedFunnelFoldersMap);
        // Keep the "General" folder as the first folder
        setFunnelFolders([
          updatedFunnelFolders[0],
          ...updatedFunnelFolders.slice(1).sort(defaultSortOrder("label")),
        ]);
        setFunnels(funnelObjs);
        setIsLoadingFunnels(false);
        setIsFunnelsLoaded(true);
        const isFunnelRendered = function(funnelFolderName) {
          return selectedFunnelFolder.value === DEFAULT_FUNNEL_FOLDER_NAME
            ? funnelFolderName === DEFAULT_FUNNEL_FOLDER_NAME ||
            funnelFolderName === "" ||
            funnelFolderName === undefined
            : funnelFolderName === selectedFunnelFolder.value;
        }
        res[funnelPromiseIndex].forEach((funnelDoc) => {
          const funnelId = funnelDoc.id;
          const folder = funnelDoc.data().folder;

          if (isFunnelRendered(folder)) {
            const cancelTokenName = "queryFunnelSteps" + funnelId;
            api.cancel(cancelTokenName);
            api.cancel("readFunnelSteps" + funnelId);
            addOngoingQueries(cancelTokenName);
            api
              .queryFunnelSteps(authUser, {
                funnelId,
                profileId: profileId,
                startDate: startDate.format("YYYY-MM-DD"),
                endDate: endDate.format("YYYY-MM-DD"),
                segments: JSON.stringify(
                  Object.keys(selectedSegments).map((key) => {
                    return key;
                  })
                ),
                filter: JSON.stringify(appliedFilters),
              })
              .then((res) => {
                if (!_isMounted.current) {
                  return;
                }

                removeOngoingQueries(cancelTokenName);
                if (!isFunnelRendered(folder)) {
                  // Do not launch read query.
                  // Todo : selectedFunnelFolder seems not up to date
                  return;
                }
                // handle bytesBilledLimitExceeded error
                if (
                  res.data.status === 400 &&
                  res.data.result === "bytesBilledLimitExceeded"
                ) {
                  setFunnelError(DEFAULT_ERROR);
                  setAlert({
                    show: true,
                    type: "danger",
                    message: BYTES_BILL_LIMIT_EXCEED_ERROR,
                    count: alert.count + 1,
                  });
                  return;
                }
                const { queryId, key } = res.data.data;
                if (key && queryId) {
                  readFunnelSteps(authUser, queryId, key, funnelId);
                  setFunnelError(null);
                }
              })
              .catch((error) => {
                if (!_isMounted.current) {
                  return false;
                }
                removeOngoingQueries(cancelTokenName);
                if (api.isCancel(error)) {
                  captureQueryCancelMsgToSentry("FunnelSteps");
                  return;
                }
                captureExceptionToSentry(error);
                setAlert({
                  show: true,
                  type: "danger",
                  message: DEFAULT_ERROR,
                  count: alert.count + 1,
                });
                setFunnelError(DEFAULT_ERROR);
              });
          }
        });
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isDependenciesLoaded,
    profileId,
    startDate,
    endDate,
    selectedSegments,
    appliedFilters,
    action,
    selectedFunnelFolder,
  ]);

  // Select the caching funnel folder
  useEffect(() => {
    const initialFolder =
      getProfilePref(profileId, "lastFunnelFolder") ||
      DEFAULT_FUNNEL_FOLDER_NAME;

    if (initialFolder) {
      setSelectedFunnelFolder({
        label: initialFolder,
        value: initialFolder,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId]);

  const handleClickRow = (e) => {
    const funnelId = e.currentTarget.dataset["funnelid"];
    props.history.push(`/profile/${profileId}/funnels/view/${funnelId}`);
  };

  const renderStartedSessionsCell = ({ row }) => {
    return (
      <span>
        {row.original.StartedSessionCount === -1 ? (
          <i className="fa fa-spinner fa-spin" />
        ) : (
          row.original.StartedSessionCount.toLocaleString()
        )}
      </span>
    );
  };

  const renderCompletedSessionsCell = ({ row }) => {
    return (
      <span>
        {row.original.CompletedSessionCount === -1 ? (
          <i className="fa fa-spinner fa-spin" />
        ) : (
          row.original.CompletedSessionCount.toLocaleString()
        )}
      </span>
    );
  };

  const renderConversionRateCell = ({ row }) => {
    const conv =
      row.original.CompletedSessionCount > 0
        ? (row.original.CompletedSessionCount /
            row.original.StartedSessionCount) *
          100
        : 0;
    return (
      <span style={{ color: "#1cc88a" }}>
        {row.original.StartedSessionCount === -1 ? (
          <i className="fa fa-spinner fa-spin" />
        ) : (
          Math.round((conv + Number.EPSILON) * 100) / 100 + "%"
        )}
      </span>
    );
  };

  const renderActionCell = ({ value }) => {
    return (
      <>
        <Button
          variant="primary"
          size="small"
          data-funnelid={value}
          onClick={handleClickRow}
        >
          View Funnel
        </Button>
      </>
    );
  };

  const renderOwnerCell = ({ value }) => {
    return value ? (
      <>
        <UserAvatar
          name={value.name}
          className="rounded-circle mr-2"
          size={32}
          photoUrl={value.photoURL}
        />
        {value.name}
      </>
    ) : (
      <></>
    );
  };

  const columns = useMemo(() => {
    return [
      {
        Header: "Funnel Name",
        accessor: "Name",
      },
      { Header: "Owner", accessor: "Owner", Cell: renderOwnerCell },
      {
        Header: "Steps",
        accessor: "StepCount",
      },
      {
        Header: "Started Sessions",
        accessor: "StartedSessionCount",
        Cell: renderStartedSessionsCell,
      },
      {
        Header: "Completed Sessions",
        accessor: "CompletedSessionCount",
        Cell: renderCompletedSessionsCell,
      },
      {
        Header: "Funnel Conversion Rate",
        accessor: "ConversionRate",
        Cell: renderConversionRateCell,
      },
      {
        Header: "",
        accessor: "ID",
        Cell: renderActionCell,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser.isAdmin]);

  const filteredFunnels = useMemo(() => {
    if (
      selectedFunnelFolder.value === "" ||
      selectedFunnelFolder.value === DEFAULT_FUNNEL_FOLDER_NAME ||
      !funnelFolders.find(
        (folder) => folder.value === selectedFunnelFolder.value
      )
    ) {
      return funnels.filter(
        (funnel) =>
          funnel.folder === "" ||
          funnel.folder === DEFAULT_FUNNEL_FOLDER_NAME ||
          funnel.folder === undefined
      );
    }

    return funnels.filter(
      (funnel) => funnel.folder === selectedFunnelFolder.value
    );
  }, [selectedFunnelFolder, funnels, funnelFolders]);

  const { getTableBodyProps, headerGroups, prepareRow, page } = useTable(
    {
      columns,
      data: filteredFunnels,
      initialState: {
        hiddenColumns: [],
        sortBy: [{ id: "Name", desc: false }],
      },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const handleClickFunnelFolderButton = (e) => {
    // Ongoing requests are canceled by a useEffect
    setSelectedFunnelFolder({
      label: e.currentTarget.value,
      value: e.currentTarget.value,
    });

    saveProfilePref(profileId, "lastFunnelFolder", e.currentTarget.value);
  };

  return (
    <Fragment>
      <div className="row">
        {funnelError && (
          <Alert show={alert.show} type={alert.type} message={alert.message} />
        )}
      </div>
      <div className="row">
        <div className="col-12 mb-3">
          <div className="d-flex">
            <div className="funnel-folder-btn-group flex-wrap">
              {funnelFolders.map((folder, index) => {
                return (
                  <Button
                    innerRef={funnelFolderButtonRef}
                    key={index}
                    className={`funnel-folder-btn ${
                      selectedFunnelFolder.value === folder.value
                        ? "funnel-folder-btn-active"
                        : ""
                    }`}
                    onClick={handleClickFunnelFolderButton}
                    value={folder.value}
                  >
                    {folder.label}
                    {funnelFoldersMap ? (
                      <span className="funnels-number">
                        {funnelFoldersMap.get(folder.label)}
                      </span>
                    ) : (
                      ""
                    )}
                  </Button>
                );
              })}
            </div>
            <div className="ml-auto flex-shrink-0">
              {authUser.isAdmin && funnels.length < 100 && (
                <Link
                  to={`/profile/${profileId}/funnels/edit`}
                  className="btn btn-sm btn-primary"
                >
                  <span className="fa fa-plus"></span> Add New Funnel
                </Link>
              )}
            </div>
          </div>

          <div className="card shadow">
            <div className="card-body">
              {!!isFunnelsLoaded &&
                !isLoadingFunnels &&
                !!filteredFunnels.length && (
                  <>
                    <div className="d-flex mx-2 mb-2">
                      <div className="d-flex small mt-2 mr-auto">
                        {filteredFunnels.length} / 10 Funnels
                      </div>
                    </div>
                    <table className="table table-hover table-sm ui-table funnel-table">
                      <thead className=" thead-dark">
                        {headerGroups.map((headerGroup) => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th {...column.getHeaderProps()}>
                                {column.render("Header")}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                          prepareRow(row);
                          return (
                            <tr {...row.getRowProps()}>
                              {row.cells.map((cell) => {
                                return (
                                  <td
                                    {...cell.getCellProps()}
                                    data-funnelid={row.values.ID}
                                    onClick={handleClickRow}
                                  >
                                    {cell.render("Cell")}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </>
                )}
              {isLoadingFunnels && (
                <LoaderWithText
                  text="Loading Funnels"
                  style={{ margin: "30px 0" }}
                />
              )}
              {isFunnelsLoaded && !isLoadingFunnels && !funnels.length && (
                <OutlinedContainer padding={`60px 0`}>
                  <span className="fa fa-2x fa-filter"></span>
                  <h2 className="h4 font-weight-lighter my-4">
                    No Event Funnels Yet
                  </h2>
                  {authUser.isAdmin && (
                    <Link
                      className="btn btn-secondary btn-small"
                      to={`/profile/${profileId}/funnels/edit`}
                    >
                      Create New Funnel
                    </Link>
                  )}
                  <p className="mt-2"></p>
                  <p className="small mt-5 mb-0">
                    Not sure where to start?{` `}
                    <Link
                      to={{
                        pathname:
                          "https://support.insightech.com/support/solutions/articles/51000185518-setting-up-your-funnel",
                      }}
                      target="_blank"
                    >
                      See how to create funnels
                    </Link>{" "}
                    here or visit our{" "}
                    <Link
                      to={{ pathname: "https://support.insightech.com/" }}
                      target="_blank"
                    >
                      Help Center
                    </Link>
                  </p>
                </OutlinedContainer>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

Funnels.propTypes = {};
Funnels.defaultProps = {};

export default withRouter(Funnels);
