import React, { useContext, useState, useEffect, Fragment } from "react";
import { UserDataContext } from "../../../../components/app/UserData";
import { SegmentsContext } from "./../../../../context/Segments";
import { AuthContext } from "../../../../components/auth/FirebaseAuthContext";
import { Link } from "react-router-dom";
import api from "./../../../../api";
import Timestamp from "react-timestamp";
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";
import Pagination from "../../../../components/app/Pagination";
import ReplayViewer from "../../../../components/app/ReplayViewer";
import Alert from "../../../../components/share/Alert";
import "./style.scss";
import {
  BYTES_BILL_LIMIT_EXCEED_ERROR,
  DEFAULT_ERROR,
} from "../../../../constants/error";
import { ToggleButton } from "../../../../components/share/InsightUI";
import { FaRegAngry } from "react-icons/fa";
import { LuGoal } from "react-icons/lu";
import FlagIcon from "../../../../components/share/FlagIcon";
import countries from "./../../../../inc/country.json";
import {
  capitalize,
  captureExceptionToSentry,
  captureQueryCancelMsgToSentry,
  captureReadCancelMsgToSentry,
  thousandSeparatorAndFixedDecimal,
} from "../../../../utils";
import { fetchProfile } from "../../../../lib/firebase/profile";
import SortingDropdown from "../../../../components/app/SortingDropdown";
import { SessionSortOptions } from "../../../../helpers/sessionSortOptions";
import { Tooltip as ReactTooltip } from "react-tooltip";

const SessionList = (props) => {
  momentDurationFormatSetup(moment);

  const defaultSelectedSegments = {};

  const noteId =
    props.location.state?.replayOptions?.noteId ||
    props.location.state?.noteData?.id;
  const { authUser } = useContext(AuthContext);
  const [loadingData, setLoadingData] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState("");
  const [isDependenciesLoaded, setIsDependenciesLoaded] = React.useState(false);
  const [isReplayListLoaded, setIsReplayListLoaded] = React.useState(false);
  const { profileId, visitorId, sessionNumber } = props.match.params;
  const {
    setQueryId,
    isInitialFiltersLoaded,
    initialDates,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    setSelectedStartDate,
    setSelectedEndDate,
    setSelectedDateRange,
    setMySelectedDates,
    appliedFilters,
    setAppliedFilters,
    userConditions,
    setUserConditions,
    replayList,
    setReplayList,
    replayListQuery,
    setReplayListQuery,
    replayListPage,
    setReplayListPage,
    replayListPageSize,
    setReplayListPageSize,
    replayListTotal,
    setReplayListTotal,
    reloadApi,
    setReloadApi,
    isApiServerLoading,
    isOverrideFiltersLoaded,
    onlyRecordedSessions,
    setOnlyRecordedSessions,
  } = useContext(UserDataContext);

  const {
    segments,
    setSelectedSegments,
    selectedSegments,
    isInitialSegmentsLoaded,
  } = useContext(SegmentsContext);

  const [isLiteModeEnabled, setIsLightModeEnabled] = useState(null);

  const [sortField, setSortField] = useState("timestamp");
  const [sortOrder, setSortOrder] = useState("desc");
  const [selectedSortOption, setSelectedSortOption] =
    useState("timestamp_desc");

  const [alert, setAlert] = useState({
    show: false,
    type: "",
    message: "",
    count: 0,
  });

  const [visitedReplays, setVisitedReplays] = useState({});
  const _isMounted = React.useRef(true);

  const handleClickSort = (optionValue) => {
    const [field, order] = optionValue?.split("_");
    setSortField(field);
    setSortOrder(order);
    setReloadApi(true);
    setSelectedSortOption(optionValue);
  };

  const handleOnSessionsToggle = () => {
    setOnlyRecordedSessions(!onlyRecordedSessions);
    setReloadApi(true);
  };

  const resetSelectedSegments = () => {
    Object.keys(segments).forEach((key) => {
      if (segments[key].default === true) defaultSelectedSegments[key] = true;
    });
    setSelectedSegments({ ...defaultSelectedSegments });
  };

  const resetDateRange = () => {
    //reset date range
    setSelectedStartDate(initialDates.startDate);
    setStartDate(initialDates.startDate);
    setSelectedEndDate(initialDates.endDate);
    setMySelectedDates({
      startDate: initialDates.startDate,
      endDate: initialDates.endDate,
    });
    setEndDate(initialDates.endDate);
    setSelectedDateRange(initialDates);
  };

  const resetReplayByDefault = () => {
    resetDateRange();
    resetSelectedSegments();
    setAppliedFilters({});
  };

  //If the previous link is a note link, redirect to replay page
  //If the previous link is from the replay/form analysis/page anaylis list, back to the list page
  //If the previous link is form "View Replay" in the note page, back to the note page
  //If user comes via the replay link, back to the replay page
  //If user open a new tab and phase the replay link, back to browser empty page
  const handleClickOverlayClose = () => {
    const fallbackUrl = `/profile/${profileId}/replay/list`;
    const prePage = window.location.href;

    if (props.location.state?.from === `/note/${profileId}/${noteId}`) {
      props.history.push(fallbackUrl);
      //Reload the replay data
      resetReplayByDefault();
    } else {
      window.history.back();
      setTimeout(() => {
        if (window.location.href === prePage) {
          props.history.push(fallbackUrl);
          resetReplayByDefault();
        }
      }, 500);
    }
  };

  const readSessionData = (
    profileId,
    queryId,
    queryKey,
    pageNumber,
    pageSize
  ) => {
    api.cancel(api.readSessionListToken);
    setLoadingStatus("read");
    setLoadingData(true);
    api
      .readSessionList(authUser, {
        profileId: profileId,
        queryId: queryId,
        key: queryKey,
        page: pageNumber,
        pageSize: pageSize,
      })
      .then((res) => {
        if (!_isMounted.current) {
          return;
        }
        if (res.status === 200) {
          if (res.data.status === 100) {
            // job is not completed, keep loader setLoadingData(true);
            readSessionData(profileId, queryId, queryKey, pageNumber, pageSize);
            setAlert({
              show: false,
              type: "",
              message: "",
              count: alert.count + 1,
            });
          } else if (res.data.status === 200) {
            setLoadingStatus("rendering");
            setIsReplayListLoaded(true);
            setReloadApi(false);
            setReplayListTotal(res.data.data.totalRows);
            if (res.data.data.totalRows > 0) {
              setReplayList(res.data.data.records);
            } else {
              setReplayList([]);
            }
            setLoadingData(false);
            setAlert({
              show: false,
              type: "",
              message: "",
              count: alert.count + 1,
            });
          } else {
            // display error message
            setLoadingData(false);
            setAlert({
              show: true,
              type: "danger",
              message: DEFAULT_ERROR,
              count: alert.count + 1,
            });
          }
        } else {
          // display network error message
          setLoadingData(false);
          setAlert({
            show: true,
            type: "danger",
            message: DEFAULT_ERROR,
            count: alert.count + 1,
          });
        }
      })
      .catch((err) => {
        if (!_isMounted.current) {
          return;
        }
        if (api.isCancel(err)) {
          // Let cancelling part do setLoadingData(false);
          captureReadCancelMsgToSentry("SessionList");
          return;
        }
        setLoadingData(false);
        captureExceptionToSentry(err);
        setAlert({
          show: true,
          type: "danger",
          message: DEFAULT_ERROR,
          count: alert.count + 1,
        });
      });
  };

  const querySessionData = (
    profileId,
    startDate,
    endDate,
    segments,
    filters,
    recordedSessions,
    sortField,
    sortOrder
  ) => {
    api.cancel(api.querySessionListToken);
    api.cancel(api.readSessionListToken);
    setLoadingStatus("query");
    setLoadingData(true);
    api
      .querySessionList(authUser, {
        profileId: profileId,
        startDate: startDate.format("YYYY-MM-DD"),
        endDate: endDate.format("YYYY-MM-DD"),
        segments: JSON.stringify(segments),
        filter: JSON.stringify(filters),
        recordedSessions: recordedSessions,
        sort: `${sortField}-${sortOrder}`,
      })
      .then((res) => {
        if (!_isMounted.current) {
          return;
        }
        if (res.status === 200) {
          if (res.data.status === 200) {
            
            //setLoadingStatus("query");
            setReplayListQuery({
              id: res.data.data.queryId,
              key: res.data.data.key,
            });
            setQueryId(res.data.data.queryId);
            setReplayListPage(0);
            readSessionData(
              profileId,
              res.data.data.queryId,
              res.data.data.key,
              0,
              replayListPageSize
            );
            setAlert({
              show: false,
              type: "",
              message: "",
              count: alert.count + 1,
            });
          } else {
            // handle bytesBilledLimitExceeded error
            if (
              res.data.status === 400 &&
              res.data.result === "bytesBilledLimitExceeded"
            ) {
              setLoadingData(false);
              setAlert({
                show: true,
                type: "danger",
                message: BYTES_BILL_LIMIT_EXCEED_ERROR,
                count: alert.count + 1,
              });
              return;
            }

            // display error message
            setLoadingData(false);
            setAlert({
              show: true,
              type: "danger",
              message: DEFAULT_ERROR,
              count: alert.count + 1,
            });
          }
        } else {
          // display network error message
          setLoadingData(false);
          setAlert({
            show: true,
            type: "danger",
            message: "Something went wrong, please try again later",
            count: alert.count + 1,
          });
        }
      })
      .catch((err) => {
        if (!_isMounted.current) {
          return;
        }
        if (api.isCancel(err)) {
          // Let Let cancelling part do setLoadingData(false);
          captureQueryCancelMsgToSentry("SessionList");
          return;
        }
        setLoadingData(false);
        captureExceptionToSentry(err);
        setAlert({
          show: true,
          type: "danger",
          message: DEFAULT_ERROR,
          count: alert.count + 1,
        });
      });
  };

  useEffect(() => {
    if (!visitorId && !sessionNumber) {
      if (
        !isDependenciesLoaded &&
        isInitialFiltersLoaded &&
        isInitialSegmentsLoaded &&
        appliedFilters &&
        selectedSegments &&
        !isApiServerLoading &&
        startDate &&
        endDate
      ) {
        setIsDependenciesLoaded(true);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isInitialFiltersLoaded,
    isInitialSegmentsLoaded,
    appliedFilters,
    selectedSegments,
    startDate,
    endDate,
    isApiServerLoading,
  ]);

  useEffect(() => {
    // Automatically add visitor id as User ID filter
    if (visitorId && !sessionNumber) {
      if (
        !isDependenciesLoaded &&
        isInitialFiltersLoaded &&
        isInitialSegmentsLoaded &&
        appliedFilters &&
        selectedSegments &&
        !isApiServerLoading &&
        startDate &&
        endDate
      ) {
        const updatedFilters = { ...appliedFilters };
        const updatedUserConditions = [...userConditions];
        updatedFilters.userConditions = appliedFilters.userConditions
          ? appliedFilters.userConditions
          : [];
        if (
          updatedUserConditions.length === 1 &&
          !updatedUserConditions.attribute
        ) {
          // Do this if there is no cached/saved filter condition
          updatedFilters.userConditions[0] = {
            attribute: "VisitorID",
            path: "",
            unit: null,
            operator: "is",
            values: [visitorId],
          };
          updatedUserConditions[0] = {
            attribute: "VisitorID",
            path: "",
            unit: null,
            operator: "is",
            values: [visitorId],
          };
        } else {
          let hasVisitorId = false;
          // Check if VisitorID filter is already present
          for (let i = 0, len = updatedUserConditions.length; i < len; i++) {
            const condition = updatedUserConditions[i];
            if (condition.attribute === "VisitorID") {
              hasVisitorId = true;
            }
          }
          if (!hasVisitorId) {
            updatedFilters.userConditions.push({
              attribute: "VisitorID",
              path: "",
              unit: null,
              operator: "is",
              values: [visitorId],
            });
            updatedUserConditions.push({
              attribute: "VisitorID",
              path: "",
              unit: null,
              operator: "is",
              values: [visitorId],
            });
          }
        }
        setAppliedFilters(updatedFilters);
        setUserConditions(updatedUserConditions);
        setIsDependenciesLoaded(true);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isInitialFiltersLoaded,
    isInitialSegmentsLoaded,
    appliedFilters,
    userConditions,
    selectedSegments,
    startDate,
    endDate,
    visitorId,
    isApiServerLoading,
  ]);

  useEffect(() => {
    if (
      isDependenciesLoaded &&
      appliedFilters &&
      selectedSegments &&
      startDate &&
      endDate &&
      (!props.location.state || !props.location.state.overrideFilters)
    ) {
      const selectedSegmentsArray = Object.keys(selectedSegments).map((key) => {
        return key;
      });
      querySessionData(
        profileId,
        startDate,
        endDate,
        selectedSegmentsArray,
        appliedFilters,
        onlyRecordedSessions,
        sortField,
        sortOrder
      );
    }
    if (
      isDependenciesLoaded &&
      appliedFilters &&
      selectedSegments &&
      startDate &&
      endDate &&
      props.location.state &&
      props.location.state.overrideFilters &&
      (!isReplayListLoaded || reloadApi) &&
      isOverrideFiltersLoaded
    ) {
      const selectedSegmentsArray = Object.keys(selectedSegments).map((key) => {
        return key;
      });
      querySessionData(
        profileId,
        startDate,
        endDate,
        selectedSegmentsArray,
        appliedFilters,
        onlyRecordedSessions,
        sortField,
        sortOrder
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.location.state,
    isOverrideFiltersLoaded,
    isDependenciesLoaded,
    appliedFilters,
    selectedSegments,
    startDate,
    endDate,
    onlyRecordedSessions,
    sortField,
    sortOrder,
  ]);

  // Main useEffect used for unmounting
  useEffect(() => {
    return () => {
      api.cancel(api.querySessionListToken);
      api.cancel(api.readSessionListToken);
      _isMounted.current = false;
    };
  }, []);

  // get profile lite tracking mode enabled
  useEffect(() => {
    fetchProfile({ profileId }).then((res) => {
      const isEnabled = res?.data?.data?.liteTrackingSettings?.enabled || false;
      setIsLightModeEnabled(isEnabled);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId]);

  return (
    <Fragment>
      <div
        className={`d-flex justify-content-${
          isLiteModeEnabled ? "between" : "end"
        } align-items-center mb-4`}
      >
        {isLiteModeEnabled && (
          <ToggleButton
            opt1Label="All Sessions"
            opt2Label="Recorded Sessions"
            isActive={onlyRecordedSessions}
            onChange={handleOnSessionsToggle}
          />
        )}
        <SortingDropdown
          sortOptions={SessionSortOptions}
          onChange={handleClickSort}
          sortOrder={sortOrder}
          selectedSortOption={selectedSortOption}
        />
      </div>
      {loadingData && (
        <div className="col-12 mb-4">
          <span>
            <i className="fa fa-spinner fa-spin" />
            {loadingStatus === "query" && "Querying data..."}
            {loadingStatus === "read" && "Reading data..."}
            {loadingStatus === "rendering" && "Rendering result..."}
            {loadingStatus === "" && "Loading data..."}
          </span>
        </div>
      )}
      {!loadingData && alert.show && (
        <div className="row">
          <Alert
            show={alert.show}
            type={alert.type}
            message={alert.message}
            count={alert.count}
          />
        </div>
      )}
      {!loadingData && !alert.show && replayList !== null && replayList.length === 0 && (
        <div>No result</div>
      )}
      {!sessionNumber &&
        !loadingData &&
        !alert.show &&
        replayList !== null &&
        replayList.map((session, key) => {
          const duration = moment.duration(session.duration, "milliseconds");
          const sessionStartTime = new Date(
            session.sessionStartTime / 1000 / 1000
          );
          const isWithin24Hours =
            new Date().getTime() - sessionStartTime.getTime() <=
            24 * 60 * 60 * 1000;
          const noRecordedSession =
            duration.format("hms") === "0" || session.pageviewReplayCount === 0;

          return (
            <div
              key={session.visitorId + "." + session.sessionNumber + "." + key}
              className="row"
            >
              <div className="col-12 mb-3">
                <div
                  className={`card shadow h-100
                  ${
                    visitedReplays[
                      `${session.visitorId + "." + session.sessionNumber}`
                    ] && "visited-replay"
                  }
                `}
                >
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col-auto mr-2">
                        {noRecordedSession ? (
                          <i className="fa fa-stop-circle text-secondary fa-2x"></i>
                        ) : (
                          <Link
                            to={{
                              pathname:
                                "/profile/" +
                                profileId +
                                "/replay/session/" +
                                session.visitorId +
                                "/" +
                                session.sessionNumber,
                              state: { autoPlay: true },
                            }}
                          >
                            <i className="fa fa-play-circle text-primary fa-2x"></i>
                          </Link>
                        )}
                      </div>
                      <div className="col mr-2">
                        <div className="row">
                          <div className="col small">
                            <i className="fas fa-user fa-fw mr-1"></i>
                            {session.visitorId.toUpperCase()}
                            <br />
                            {session.sessionNumber > 1
                              ? "Return visitor"
                              : "New visitor"}
                          </div>
                          <div className="col-3 small">
                            <Timestamp
                              date={sessionStartTime}
                              relative={false}
                            />
                            {!isWithin24Hours ? (
                              ""
                            ) : (
                              <>
                                {" "}
                                (
                                <Timestamp
                                  date={sessionStartTime}
                                  relative={true}
                                />
                                )
                              </>
                            )}
                            <br />
                            Duration: {duration.format("h[h] m[m] s[s]")}
                          </div>
                          <div className="col small">
                            {session.pageviewCount > 1
                              ? session.pageviewCount + " Pageviews"
                              : session.pageviewCount + " Pageview"}{" "}
                            /{" "}
                            {session.clickCount > 1
                              ? session.clickCount + " Clicks"
                              : session.clickCount + " Click"}
                            <br />
                            {session.rageClickCount > 0 && (
                              <FaRegAngry
                                className="mr-2 text-warning"
                                size={18}
                                data-tooltip-id="replay-tooltip"
                                data-tooltip-content="Rage Click Session"
                              />
                            )}
                            {(session.conversions > 0 ||
                              session.revenue > 0) && (
                              <span
                                className="session-con-rev"
                                data-tooltip-id="replay-tooltip"
                                data-tooltip-content={`${
                                  session.conversions > 0 && "Converted Session"
                                } ${
                                  session.revenue > 0
                                    ? `($${thousandSeparatorAndFixedDecimal(
                                        session.revenue,
                                        0,
                                        2
                                      )})`
                                    : ""
                                }`}
                              >
                                {session.conversions > 0 && (
                                  <LuGoal className=" text-success" size={16} />
                                )}
                                {session.revenue > 0 && (
                                  <span className="ml-1">
                                    $
                                    {thousandSeparatorAndFixedDecimal(
                                      session.revenue,
                                      0,
                                      2
                                    )}
                                  </span>
                                )}
                              </span>
                            )}
                          </div>
                          <div className="col small">
                            {session.source === "(direct)"
                              ? session.source
                              : session.source + " / " + session.medium}
                            <br />
                            {session.os} / {session.browser}
                          </div>
                          <div className="col small">
                            <FlagIcon countryCode={session.country} />{" "}
                            {countries.countries[session.country]}
                            <br />
                            {session.city !== "?" && capitalize(session.city)}
                            {session.city &&
                              session.city !== "?" &&
                              session.region &&
                              session.region !== "?" &&
                              " / "}
                            {session.region !== "?" &&
                              session.region.toUpperCase()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ReactTooltip id="replay-tooltip" className="tooltip-md" />
            </div>
          );
        })}
      {!loadingData && !alert.show && replayList !== null && (
        <Pagination
          activePage={replayListPage + 1}
          totalRows={replayListTotal}
          pageSize={replayListPageSize}
          changeHandler={(pageNumber, pageSize) => {
            readSessionData(
              profileId,
              replayListQuery.id,
              replayListQuery.key,
              pageNumber - 1,
              pageSize
            );
            setReplayListPage(pageNumber - 1);
            setReplayListPageSize(pageSize);
          }}
        />
      )}
      {visitorId && sessionNumber && isLiteModeEnabled !== null && (
        <ReplayViewer
          {...props}
          title="Analysis Replay"
          visitedReplays={visitedReplays}
          setVisitedReplays={setVisitedReplays}
          handleClickCancel={handleClickOverlayClose}
          liteMode={isLiteModeEnabled}
        />
      )}
    </Fragment>
  );
};

export default SessionList;
