import React, { useContext, useState, useEffect, useRef } from "react";
// import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { Button } from "./../../../components/share/InsightUI";
import LoaderWithText from "./../../../components/share/LoaderWithText";
import { UserDataContext } from "./../../app/UserData";
import { AuthContext } from "./../../auth/FirebaseAuthContext";
import { SegmentsContext } from "./../../../context/Segments";
import { fetchFunnel } from "./../../../lib/firebase/funnels";
import FunnelGraph from "./FunnelGraph";
import {
  captureExceptionToSentry,
  captureQueryCancelMsgToSentry,
  captureReadCancelMsgToSentry,
  deepCopy,
} from "./../../../utils";
import api from "./../../../api";
import "./style.scss";
import {
  BYTES_BILL_LIMIT_EXCEED_ERROR,
  DEFAULT_ERROR,
} from "../../../constants/error";
import Alert from "../../share/Alert";
import FunnelWidget from "./FunnelWidget";

function FunnelViewer(props) {
  const { funnelId } = props;
  const { authUser } = useContext(AuthContext);
  const {
    activeProfileId,
    startDate,
    endDate,
    appliedFilters,
    isInitialFiltersLoaded,
    isApiServerLoading,
  } = useContext(UserDataContext);
  const { selectedSegments, isInitialSegmentsLoaded } =
    useContext(SegmentsContext);
  const [isFunnelStepsLoading, setIsFunnelStepsLoading] = useState(false);
  const [funnelStepsFilters, setFunnelStepsFilters] = useState([]);
  const [funnelStepsData, setFunnelStepsData] = useState([]);
  const [funnelData, setFunnelData] = useState({
    name: "",
    description: "",
    tags: [],
    steps: [],
  });
  const [stepsQueryCredentials, setStepsQueryCredentials] = useState({
    queryId: null,
    key: null,
  });
  const [isDependenciesLoaded, setIsDependenciesLoaded] = useState(false);
  const [funnelGraphError, setFunnelGraphError] = useState();
  const [alert, setAlert] = useState({
    show: false,
    type: "",
    message: "",
    count: 0,
  });
  const _isMounted = useRef(true);

  const handleClickEdit = () => {
    props.history.push(`/profile/${activeProfileId}/funnels/edit/${funnelId}`);
  };

  const readFunnelSteps = (authUser, queryId, key) => {
    api.cancel("readFunnelSteps" + queryId + funnelId);
    api
      .readFunnelSteps(authUser, {
        profileId: activeProfileId,
        queryId,
        key,
        funnelId: funnelId,
      })
      .then((res) => {
        if (!_isMounted.current) {
          return;
        }
        if (res.data.status === 100) {
          readFunnelSteps(authUser, queryId, key);
        }
        if (res.data.status === 200) {
          setFunnelStepsData(res.data.data.records);
          setIsFunnelStepsLoading(false);
        }
      })
      .catch((err) => {
        if (api.isCancel(err)) {
          captureReadCancelMsgToSentry("FunnelSteps");
          return;
        }
        captureExceptionToSentry(err);
      });
  };

  useEffect(() => {
    if (
      funnelId &&
      activeProfileId &&
      isInitialFiltersLoaded &&
      isInitialSegmentsLoaded &&
      !isDependenciesLoaded &&
      !isApiServerLoading
    ) {
      setIsDependenciesLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    funnelId,
    activeProfileId,
    isInitialFiltersLoaded,
    isInitialSegmentsLoaded,
    isApiServerLoading,
  ]);

  useEffect(() => {
    if (isDependenciesLoaded && appliedFilters) {
      // Get Funnel Details
      setIsFunnelStepsLoading(true);
      fetchFunnel({ profileId: activeProfileId, funnelId }).then((res) => {
        if (!_isMounted.current) {
          return;
        }
        const data = res.data();
        if (!data) {
          props.history.push(
            `/profile/${props.match.params.profileId}/funnels/list`
          );
          return;
        }
        setFunnelData({
          name: data.name,
          description: data.description,
          openFunnel: data.openFunnel,
          tags: data.tags.map((tag) => {
            return {
              label: tag,
              value: tag,
            };
          }),
          steps: data.steps,
        });
        const stepsFilters = data.steps.map((step) => {
          const filter = JSON.parse(step.filters);
          return filter.pageContainers[0];
        });

        setFunnelStepsFilters(stepsFilters);
      });

      // Get Funnel Steps Report
      api.cancel("queryFunnelSteps" + funnelId);
      api.cancel("readFunnelSteps" + funnelId);
      api
        .queryFunnelSteps(authUser, {
          funnelId,
          profileId: activeProfileId,
          startDate: startDate.format("YYYY-MM-DD"),
          endDate: endDate.format("YYYY-MM-DD"),
          segments: JSON.stringify(
            Object.keys(selectedSegments).map((key) => {
              return key;
            })
          ),
          filter: JSON.stringify(appliedFilters),
        })
        .then((res) => {
          if (!_isMounted.current) {
            return;
          }
          // handle bytesBilledLimitExceeded error
          if (
            res.data.status === 400 &&
            res.data.result === "bytesBilledLimitExceeded"
          ) {
            setIsFunnelStepsLoading(false);
            setFunnelGraphError(DEFAULT_ERROR);
            setAlert({
              show: true,
              type: "danger",
              message: BYTES_BILL_LIMIT_EXCEED_ERROR,
              count: alert.count + 1,
            });
            return;
          }
          const { queryId, key } = res.data.data;
          if (key && queryId) {
            setStepsQueryCredentials({ queryId, key });
            setAlert({
              show: false,
              type: "",
              message: "",
              count: 0,
            });
            setFunnelGraphError(null);
          }
        })
        .catch((error) => {
          if (!_isMounted.current) {
            return false;
          }
          if (api.isCancel(error)) {
            captureQueryCancelMsgToSentry("FunnelSteps");
          } else {
            captureExceptionToSentry(error);
            setAlert({
              show: true,
              type: "danger",
              message: DEFAULT_ERROR,
              count: alert.count + 1,
            });
            setFunnelGraphError(DEFAULT_ERROR);
          }
          setIsFunnelStepsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isDependenciesLoaded,
    selectedSegments,
    appliedFilters,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    if (stepsQueryCredentials.queryId && stepsQueryCredentials.key) {
      readFunnelSteps(
        authUser,
        stepsQueryCredentials.queryId,
        stepsQueryCredentials.key
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepsQueryCredentials]);

  // Unmount
  useEffect(() => {
    return () => {
      api.cancel("queryFunnelSteps" + funnelId);
      api.cancel("readFunnelSteps" + funnelId);
      _isMounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let maxBarWidth = 1;
  let excludeFilters = [];
  let MaxSessionPct = 1;

  // add MaxSessionPct attr
  let newFunnelStepData = funnelStepsData.map((step, index) => {
    return { ...step, MaxSessionPct: MaxSessionPct };
  });

  return (
    <div className="funnel-screen funnel-viewer">
      <div className="funnel-report" id="funnel-report">
        <div className="row mt-2">
          {funnelGraphError && (
            <Alert
              show={alert.show}
              type={alert.type}
              message={alert.message}
            />
          )}
        </div>
        {funnelData.name && (
          <div className="funnel-details">
            <h1 className="h4 text-gray-900">{funnelData.name}</h1>
            <div className="funnel-meta mr-auto">
              <p className="funnel-description mb-1 small">
                {funnelData.description}
              </p>
              <div className="funnel-tags">
                <small className="mr-2" style={{ color: "black" }}>
                  {funnelData.openFunnel ? "Open Funnel" : "Closed Funnel"}
                </small>
                {funnelData.tags.map((tag) => {
                  return (
                    <span key={tag.value} className="badge badge-secondary">
                      {tag.value}
                    </span>
                  );
                })}
              </div>
            </div>
            <div className="funnel-actions">
              {authUser.isAdmin && (
                <Button size="small" onClick={handleClickEdit}>
                  <span className="fa fa-pencil-alt"></span>
                </Button>
              )}
            </div>
          </div>
        )}

        <FunnelWidget
          funnelStepsData={funnelStepsData}
          isFunnelStepsLoading={isFunnelStepsLoading}
          funnelGraphError={funnelGraphError}
        />

        <div
          className={`
        funnel-graph-container
      `}
        >
          <div className="container">
            <div className="row">
              {!!funnelData.name &&
                !!funnelStepsData.length &&
                !!funnelStepsFilters.length &&
                !isFunnelStepsLoading && (
                  <>
                    <div className="col-10 offset-md-1">
                      <div className="row graph-heading">
                        <div className="col">Steps</div>
                        <div className="col">Sessions</div>
                        <div className="col"></div>
                        <div className="col">Drop Off</div>
                      </div>
                    </div>
                    {newFunnelStepData.map((step, index) => {
                      const prevCount =
                        index === 0
                          ? step.SessionCount
                          : funnelStepsData[index - 1].SessionCount;
                      const nextCount =
                        index === funnelStepsData.length - 1
                          ? step.SessionCount
                          : funnelStepsData[index + 1].SessionCount;
                      const previousStepFilters = funnelStepsFilters.filter(
                        (filter, i) => {
                          if (i <= index) {
                            return true;
                          }
                          return false;
                        }
                      );
                      const dropOffFilters = deepCopy(previousStepFilters);
                      dropOffFilters[index].inclusion = false;
                      const openFunnelPrevStepFilters =
                        funnelStepsFilters.filter((filter, i) => {
                          if (i === index) {
                            return true;
                          }
                          return false;
                        });
                      const DropOffFiltersCopy = deepCopy(dropOffFilters);
                      const openFunnelDropOffFilters = DropOffFiltersCopy.slice(
                        Math.max(DropOffFiltersCopy.length - 2, 0)
                      );

                      const component = (
                        <FunnelGraph
                          MaxSessionPct={step.MaxSessionPct}
                          sessionCount={step.SessionCount}
                          prevSessionCount={prevCount}
                          nextSessionCount={nextCount}
                          stepNumber={index + 1}
                          stepName={funnelData.steps[index].name}
                          maxBarWidth={maxBarWidth}
                          lastRow={index === funnelStepsData.length - 1}
                          filters={
                            funnelData.openFunnel
                              ? openFunnelPrevStepFilters
                              : previousStepFilters
                          }
                          dropOffFilters={
                            funnelData.openFunnel
                              ? openFunnelDropOffFilters
                              : dropOffFilters
                          }
                          key={index}
                        />
                      );
                      let thisFilter = deepCopy(funnelStepsFilters[index]);
                      thisFilter.inclusion = false;
                      excludeFilters.push(thisFilter);
                      if (index > 0) {
                        maxBarWidth =
                          maxBarWidth * (step.SessionCount / prevCount);
                      }
                      return component;
                    })}
                  </>
                )}
              {!isFunnelStepsLoading && !!funnelGraphError && (
                <div
                  style={{
                    display: "flex",
                    flexGrow: 1,
                    justifyContent: "center",
                  }}
                >
                  <LoaderWithText
                    style={{ margin: "0 auto" }}
                    text={DEFAULT_ERROR}
                    isIconShowed={false}
                  />
                </div>
              )}
              {!!isFunnelStepsLoading && !funnelGraphError && (
                <div
                  style={{
                    display: "flex",
                    flexGrow: 1,
                    justifyContent: "center",
                  }}
                >
                  <LoaderWithText
                    style={{ margin: "0 auto" }}
                    text="Loading Funnel Graph"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

FunnelViewer.propTypes = {};
FunnelViewer.defaultProps = {};

export default withRouter(FunnelViewer);
